<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="app-ecommerce-details">
          <div class="row invoice-add">
            <div class="col-12">
              <div class="card" v-if="size">
                <div class="card-body">
                  <h4 class="mt-2">Размер {{ size.name }} (#{{ size.id }})</h4>
                  <div class="row">
                    <div class="col-12 mb-2 mb-md-0">
                      <ul class="product-features list-unstyled">
                        <li>
                          Комментарии: {{ size.comments }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h5>Соответствие размерам:</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <ul>
                        <li>UK: {{ size.uk }}</li>
                        <li>US: {{ size.us }}</li>
                        <li>CA: {{ size.ca }}</li>
                      </ul>
                    </div>
                    <div class="col-auto">
                      <ul>
                        <li>EU: {{ size.eu }}</li>
                        <li>UA: {{ size.ua }}</li>
                        <li>IT: {{ size.it }}</li>
                      </ul>
                    </div>
                    <div class="col">
                      <ul>
                        <li>DE: {{ size.de }}</li>
                        <li>CHINA: {{ size.china }}</li>
                        <li>UNIVERSAL: {{ size.universal }}</li>
                      </ul>
                    </div>
                  </div>
                  <hr>
                  <h5 class="mt-5">Категории</h5>
                  <div class="row mb-1">
                    <div class="col-12 col-md-3">
                      <TreeSelect
                          id="category"
                          v-model="form.category_id"
                          :options="categories"
                          :searchable="true"
                          :normalizer="normalizer"
                          placeholder="Выберите категорию"
                      />
                    </div>
                    <div class="col-12 col-md-3">
                      <button @click="addCategory" class="btn btn-primary btn-block">Добавить категорию</button>
                    </div>
                  </div>
                  <div class="position-relative table-responsive">
                    <table class="table b-table">
                      <thead>
                      <!---->
                      <tr>
                        <th>#</th>
                        <th>Название</th>
                        <th>Пользователь</th>
                        <th>Путь</th>
                        <th v-if="$can('manage', 'brands')" colspan="2">Действия</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="dictionary in size.category_dictionaries" :key="dictionary.id">
                        <td>{{ dictionary.id }}</td>
                        <td>{{ dictionary.category.name }}</td>
                        <td class="text-center">{{ dictionary.user_id || '-' }}</td>
                        <td>{{ dictionary.category.full_parent_path || '/' }}</td>
                        <td>{{ dictionary.created_at | formattedDate }}</td>
                        <td v-if="$can('manage', 'brands')">
                          <button
                              class="btn btn-outline-primary btn-block"
                              @click="deleteCategoryDictionary(dictionary.id)"
                          >
                            Удалить
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <h5 class="mt-5">Пользователи</h5>
                  <div class="position-relative table-responsive">
                    <table class="table b-table">
                      <thead>
                      <!---->
                      <tr>
                        <th>#</th>
                        <th>Пользователь</th>
                        <th v-if="$can('manage', 'sizes')" colspan="2">Действия</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="dictionary in size.user_dictionaries" :key="dictionary.id">
                        <td>{{ dictionary.id }}</td>
                        <td>#{{ dictionary.user.id }} - {{ dictionary.user.name }} {{ dictionary.user.surname }}</td>
                        <td>{{ dictionary.created_at | formattedDate }}</td>
                        <td v-if="$can('manage', 'brands')">
                          <button
                              class="btn btn-outline-primary btn-block"
                              @click="deleteUserDictionary(dictionary.id)"
                          >
                            Удалить
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import TreeSelect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'SizeDetails',
  directives: {
    Ripple,
  },
  components: { TreeSelect },
  data() {
    return {
      breadcrumbs: [],
      form: {
        category_id: null,
      },
      size: {
        category_dictionaries: [],
      },
      selectedCategory: null,
      categories: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.all_children,
        };
      },
    };
  },
  async mounted() {
    await this.getSize();
    this.categories = (await this.$api.categories.select()).data;
  },
  methods: {
    async getSize() {
      this.size = (await this.$api.sizes.get(this.$route.params.id)).data;
    },
    async addCategory() {
       await this.$api.sizes.addCategory(this.$route.params.id, { category_id: this.form.category_id });
       await this.getSize();
    },
    async deleteCategoryDictionary(id) {
      await this.$api.categoryDictionary.delete(id);
      await this.getSize();
    },
    async deleteUserDictionary(id) {
      await this.$api.userDictionary.delete(id);
      await this.getSize();
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
.product-img {
  width: auto;
}
.img-block {
  max-width: 200px !important;
  text-align: center;
}
</style>
